import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Box, Section, Text } from "../../components/Core";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const LogoBox = styled(Box)`
  height: 120px;
  display: table-cell;
  vertical-align: middle;
`;


const BoxStyled = styled(Box)`
  a {
    color: ${({ dark, theme }) => dark ? theme.colors.lightShade : theme.colors.primary} !important;
    &:hover {
      text-decoration: underline !important;
      color: ${({ dark, theme }) => dark ? theme.colors.accentYellow : theme.colors.primary} !important;
    }
`;

const PartnerCard = ({
  children,
  ...rest
}) => (
  <BoxStyled pt="15px" px="30px" borderRadius={10} mb={4} {...rest}>
    <div
      data-aos="zoom-in"
      data-aos-duration="750"
      data-aos-once="true"
    >
      {children}
    </div>
  </BoxStyled>
);

const Content = () => {

  const data = useStaticQuery(graphql`
    fragment logoImage on File {
      childImageSharp {
        fixed(height: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    query {

      reseller_badge: file(relativePath: { eq: "reseller-badge-white.png" }) {
        childImageSharp {
          fixed(height: 32) {
            ...GatsbyImageSharpFixed
          }
        }  
      }

      activeg_logo: file(relativePath: { eq: "partners/activeg_logo.png" }) {
        childImageSharp {
          fixed(height: 80) {
            ...GatsbyImageSharpFixed
          }
        }  
      }

      intechrity_logo: file(relativePath: { eq: "partners/intechrity_logo.png" }) {
        childImageSharp {
          fixed(height: 80) {
            ...GatsbyImageSharpFixed
          }
        }  
      }

      jfc_logo: file(relativePath: { eq: "partners/jfc_logo.jpg" }) {
        childImageSharp {
          fixed(height: 40) {
            ...GatsbyImageSharpFixed
          }
        }  
      }

      kurve_logo: file(relativePath: { eq: "partners/kurve_logo.png" }) {
        childImageSharp {
          fixed(height: 54) {
            ...GatsbyImageSharpFixed
          }
        }  
      }

      maven_logo: file(relativePath: { eq: "partners/maven_logo.png" }) {
        childImageSharp {
          fixed(height: 46) {
            ...GatsbyImageSharpFixed
          }
        }  
      }

      zpro_logo: file(relativePath: { eq: "partners/zpro_logo.png" }) {
        ...logoImage
      }

    }
  `)

  return (
    <>
      <Section className="pt-3">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col md="9" className="order-lg-1 mb-4">
              <Text className='text-center'>
                We at Sharptree value our partner relationships with these companies.
                <Text className='text-center'>
                </Text>
                They bring decades of experience providing leading edge solutions, focused advisory services and enabling
                technology consulting services to the enterprise asset management community.
              </Text>
            </Col>
          </Row>

          <Row className="align-items-topleft justify-content-left">

            <Col md="6" lg="6" className="mt-3 mt-lg-5">
              <PartnerCard>
                <LogoBox className="mb-4" >
                  <a href="https://www.activeg.com/" target="_blank" rel="noopener noreferrer">
                    <Img fixed={data.activeg_logo.childImageSharp.fixed} />
                  </a>
                </LogoBox>
                <Text fontSize={2} lineHeight={1.75} color="darkShade">
                  ActiveG is the leader in providing innovative, advanced spatial integration solutions and services for utilities,
                  municipalities, and transit organizations. For over 25 years ActiveG has been expanding the frontiers of
                  GIS integration with innovative tools and methods. ActiveG was the first company to provide an integrated,
                  embedded digital map for asset management.
                </Text>
                <Text mt={3} fontSize={2} lineHeight={1.75} color="darkShade">
                  ActiveG delivers ArcGIS integration and management via professional services and their MapEngine, PowerSync,
                  and Nash Workflow solutions, all ArcGIS Systems Ready web applications. Continuing that pioneering tradition,
                  ActiveG continues to perform GIS services, GIS development and systems integration for our clients.
                </Text>
                <Box className="py-4">
                  <Img fixed={data.reseller_badge.childImageSharp.fixed} />
                </Box>
              </PartnerCard>
            </Col>

            <Col md="6" lg="6" className="mt-3 mt-lg-5">
              <PartnerCard>
                <LogoBox className="mb-4" >
                  <a href="https://theintechritygroup.com/" target="_blank" rel="noopener noreferrer">
                    <Img fixed={data.intechrity_logo.childImageSharp.fixed} />
                  </a>
                </LogoBox>
                <Text fontSize={2} lineHeight={1.75} color="darkShade">
                  The InTechrity Group delivers industry leading business and technology solutions to our enterprise asset management clients.
                  TIG strives to earn the position of a trusted advisor and long-term partner to our clients.
                  TIG is a uniquely qualified IBM Maximo® solution provider with over 20 years of industry and Maximo experience.
                  TIG’s professionals have worked with clients from many industries including manufacturing, pharmaceutical,
                  utilities and government.
                </Text>
                <Text mt={3} fontSize={2} lineHeight={1.75} color="darkShade">
                  The InTechrity Group provides comprehensive services to address the complete asset management lifecycle.
                  We are specifically structured to meet the needs of organizations of varying sizes with services including consulting,
                  implementation, upgrades, operational support and indispensable long-term value-added services.
                </Text>
                <Box className="py-4">
                  <Img fixed={data.reseller_badge.childImageSharp.fixed} />
                </Box>
              </PartnerCard>
            </Col>

            <Col md="6" lg="6" className="mt-3 mt-lg-5">
              <PartnerCard>
                <LogoBox className="mb-4" >
                  <a href="https://www.jfc-associates.com/" target="_blank" rel="noopener noreferrer">
                    <Img fixed={data.jfc_logo.childImageSharp.fixed} />
                  </a>
                </LogoBox>
                <Text fontSize={2} lineHeight={1.75} color="darkShade">
                  JFC &amp; Associates is a world leader in integrating Enterprise Asset Management, IoT and Artificial Intelligence.
                  JFC empowers world-class organizations by implementing and managing new technologies that accelerate their businesses
                  and increase ROI.
                </Text>
                <Text mt={3} fontSize={2} lineHeight={1.75} color="darkShade">
                  JFC is not just an IT company. JFC’s people bring hands-on experience as engineers, electricians, pipe fitters and managers.
                  They know how to make technology work on the plant floor, out in the field, or anywhere from the boiler-room to the boardroom.
                </Text>
                <Box className="py-4">
                  <Img fixed={data.reseller_badge.childImageSharp.fixed} />
                </Box>
              </PartnerCard>
            </Col>

            <Col md="6" lg="6" className="mt-3 mt-lg-5">
              <PartnerCard>
                <LogoBox className="mb-4" >
                  <a href="https://www.kurvesolutions.com/" target="_blank" rel="noopener noreferrer">
                    <Img fixed={data.kurve_logo.childImageSharp.fixed} />
                  </a>
                </LogoBox>
                <Text fontSize={2} lineHeight={1.75} color="darkShade">
                  Kurve Solutions is dedicated to delivering advanced data visualization solutions for IBM’s Maximo and Tririga.
                  Our team designs and builds innovative add-on web applications that provide real business value and usability!
                </Text>
                <Text mt={3} fontSize={2} lineHeight={1.75} color="darkShade">
                  Kurve made for Maximo and Tririga, respectively, are plug and play data visualization solutions.
                  Kurve enables users to create graphs and charts that empower them to see, understand, and act on trends in their data.
                </Text>
                <Text mt={3} fontSize={2} lineHeight={1.75} color="darkShade">
                  Let your data tell a story with Kurve!
                </Text>
              </PartnerCard>
            </Col>

            <Col md="6" lg="6" className="mt-3 mt-lg-5">
              <PartnerCard>
                <LogoBox className="mb-4" >
                  <a href="https://www.mavenasset.com/" target="_blank" rel="noopener noreferrer">
                    <Img fixed={data.maven_logo.childImageSharp.fixed} />
                  </a>
                </LogoBox>
                <Text fontSize={2} lineHeight={1.75} color="darkShade">
                  Maven Asset Management is a forward-looking company focused on developing innovative solutions for work management needs.
                  As specialists in IBM Maximo implementations and upgrades, Maven understands that a successful asset management program
                  requires more than just technology. Maven’s certified professionals bring industry knowledge, technical expertise,
                  and a proven approach to their client’s projects.
                </Text>
                <Text mt={3} fontSize={2} lineHeight={1.75} color="darkShade">
                  Maven is here to fully support organizations with their enterprise asset management initiatives.
                </Text>
                <Box className="py-4">
                  <Img fixed={data.reseller_badge.childImageSharp.fixed} />
                </Box>
              </PartnerCard>
            </Col>

            <Col md="6" lg="6" className="mt-3 mt-lg-5">
              <PartnerCard              >
                <LogoBox className="mb-4" >
                  <a href="https://zprosolutions.com/" target="_blank" rel="noopener noreferrer">
                    <Img fixed={data.zpro_logo.childImageSharp.fixed} />
                  </a>
                </LogoBox>
                <Text fontSize={2} lineHeight={1.75} color="darkShade">
                  ZPro Solutions is a technology consultancy providing solutions for enterprise asset management, data management,
                  technology implementations and associated strategic services.
                </Text>
                <Text mt={3} fontSize={2} lineHeight={1.75} color="darkShade">
                  ZPro consultants are industry leading experts with in-depth knowledge and experience of how to architect a solution
                  that positively impacts our client’s bottom line while also mitigating risks in deploying new technologies.
                </Text>
                <Box className="py-4" >
                  <Img fixed={data.reseller_badge.childImageSharp.fixed} />
                </Box>
              </PartnerCard>
            </Col>

          </Row>
        </Container>
      </Section>
    </>
  );
}

export default Content;
