import { Link } from "gatsby";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { Box, Section, Title } from "../../components/Core";

const BoxStyled = styled(Box)`
  a {
    color: ${({ dark, theme }) => dark ? theme.colors.lightShade : theme.colors.primary} !important;
    &:hover {
      text-decoration: underline !important;
      color: ${({ dark, theme }) => dark ? theme.colors.accentYellow : theme.colors.primary} !important;
    }
`;

const CTA = () => (
  <>
    <div className="overflow-hidden">
      <Section bg="greyBackground">
        <Container>
          <Row className="align-items-center">
            <Col lg="12" className="order-lg-1 mt-5 mt-lg-0">
              <div
                data-aos="fade-bottom"
                data-aos-duration="750"
                data-aos-once="true"
              >
                <Title variant="quote" className='text-center'>
                  <BoxStyled>
                    If you'd like to find out more about how you can become a Sharptree partner, <Link to="/contact">contact us</Link>.
                  </BoxStyled>
                </Title>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </div>
  </>
);

export default CTA;
