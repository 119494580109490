import React from "react";
import PageHero from "../components/PageHero";
import PageWrapper from "../components/PageWrapper";
import Seo from "../components/Seo";
import Content from "../sections/partners/Content";
import CTA from "../sections/partners/CTA";
import IBMPartner from "../sections/partners/IBMPartner";

const Partners = (props) => {
  return (
    <>
      <PageWrapper>
        <Seo
          title="Sharptree | Partners | Mobile Reimagined"
          description="We at Sharptree value our partner relationships with these companies."
          pathname={props.location.pathname}
        />
        <PageHero title="Partners" />
        <IBMPartner />
        <Content />
        <CTA />
      </PageWrapper>
    </>
  );
};
export default Partners;
