import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import { Box, Section, Text } from "../../components/Core";

const BoxStyled = styled(Box)`
  a {
    color: ${({ dark, theme }) => dark ? theme.colors.lightShade : theme.colors.primary} !important;
    &:hover {
      text-decoration: underline !important;
      color: ${({ dark, theme }) => dark ? theme.colors.accentYellow : theme.colors.primary} !important;
    }
`;

const IBMCard = ({
  children,
  ...rest
}) => (
  <BoxStyled pt="15px" px="30px" borderRadius={10} mb={4} {...rest}>
    {children}
  </BoxStyled>
);

const IBMPartner = () => {

  const data = useStaticQuery(graphql`
    query {

      ibm_partner_logo: file(relativePath: { eq: "partners/ibm_partner_logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

    }
  `)

  return (
    <>
      <Section className="pb-5">
        <Container>
          <IBMCard>
            <Row className="align-items-topleft justify-content-left justify-content-md-center">
              <Col xs="4" md="3">
                <Box className="pt-2 mb-4">
                  <a href="https://ibm.com/" target="_blank" rel="noopener noreferrer">
                    <Img fluid={data.ibm_partner_logo.childImageSharp.fluid} />
                  </a>
                </Box>
              </Col>
              <Col md="7">
                <Text fontSize={2} lineHeight={1.75} color="darkShade">
                  Sharptree is pleased to be an IBM Silver Business Partner.
                </Text>
                <Text fontSize={2} lineHeight={1.75} color="darkShade">
                  IBM is a global leader in hybrid cloud and providing enterprise class asset management software.
                  IBM’s Maximo Application Suite offers organizations of all sizes the ability to effectively manage
                  their assets and maximize the value of their investments.
                </Text>
              </Col>
            </Row>
          </IBMCard>
        </Container>
      </Section>
    </>
  );
};

export default IBMPartner;
